import React, { useState, useContext } from 'react';
// import { useNavigate } from 'react-router-dom';
import firebase from '../firebase.js';
import 'firebase/auth';
import { redirect } from "react-router-dom";

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { createTheme } from '@mui/material/styles';
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword,setPersistence,browserSessionPersistence } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import {BrowserRouter as Router} from 'react-router-dom';


function Login() {
    // const { store } = useContext(GlobalStoreContext);
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showSignUp, setShowSignUp] = useState(false);

    const handleSignUpClick = () => {
        setShowSignUp(true);
    };

    const handleCloseSignUp = () => {
        setShowSignUp(false);
    };


    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const username = formData.get("username");
        const password = formData.get("password");
      
        const auth = getAuth();
      
        try {
          // Set session persistence
          setPersistence(auth, browserSessionPersistence)
      
          // Sign in with email and password
          const userCredential = await signInWithEmailAndPassword(auth, username, password);
      
          // Signed in successfully
          const user = userCredential.user;
          console.log("User signed in:", user);
      
          // Navigate to the desired page after successful sign-in
          navigate('/Upload');
        } catch (error) {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.error("Sign-in error:", errorCode, errorMessage);
          // Handle the error or display a message to the user
        }
      };
    
      const handleRegister = (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
    
        // Directly get email and password from formData
        const email = formData.get("email");
        const password = formData.get("password");
        console.log("handled register?");
    
        // Initialize Firebase Auth
        const auth = getAuth();
    
        // Use email and password directly in createUserWithEmailAndPassword
        createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed up
                const user = userCredential.user;
                console.log("Signed Up?");
                setShowSignUp(false); // Assuming setShowSignUp is to manage UI state
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorMessage);
                console.log("NOT Signed Up?");
            });
    }

    return (
        <Grid container component="main" sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' ,backgroundColor:'#8c52ff'}}>
        <CssBaseline />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
                sx={{
                    my: 8,
                    mx: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'transparent', width: 80, height: 80 }}>
                    <img
                        src="https://i.postimg.cc/Jh7XrQTQ/Telehealth-1.png"
                        alt="SLLogo"
                        style={{ width: '100%', height: '100%', objectFit: 'contain'  }}
                    />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Email"
                        name="username"
                        autoComplete="current-username"
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Remember me"
                    />
                    <Button
                        //color = "mainPurple"
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2}} 
                    >
                        Sign In
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link href="#" variant="body2">
                                Forgot password?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="#" variant="body2" onClick={handleSignUpClick}>
                                {"Don't have an account? Sign Up"}
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Grid>
        {showSignUp && (
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square sx={{ display: 'flex', alignItems: 'center' }}>
                {/* Render your sign-up component here */}
                <Box
                    sx={{
                        my: 8,
                        mx: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    {/* Your sign-up form components go here */}
                    <Typography component="h1" variant="h5">
                        Sign up
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleRegister} sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Email"
                        name="email"
                        autoComplete="current-email"
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Remember me"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Sign Up
                    </Button>
                    
                </Box>
                </Box>
            </Grid>
        )}
    </Grid>
    );
}
export default Login;