import logo from './logo.svg';
import './App.css';
import Login from "./components/Login";
import Upload from "./components/Upload";
import Info from "./components/Info";
import Profile from "./components/Profile";
import Save from "./components/Save";
import AIChat from './components/AIChat';
import { GlobalStoreContextProvider } from './store'

import React from "react";
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
function App() {
  return (
    <>
    <GlobalStoreContextProvider>
    <BrowserRouter>
        <Routes>
            <Route
                exact
                path="/"
                element={<Login />}
            />
            <Route
                exact
                path="/upload"
                element={<Upload />}
            />
            <Route
                exact
                path="/info"
                element={<Info />}
            />
            <Route 
                exact
                path="/profile"
                element={<Profile />}
            />
            <Route 
                exact
                path="/save"
                element={<Save />}
            />
            <Route 
                exact
                path="/chat"
                element={<AIChat />}
            />
        </Routes>
    </BrowserRouter>
    </GlobalStoreContextProvider>
</>
  );
}

export default App;
