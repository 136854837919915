import React, { useState, useContext, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Divider from "@mui/material/Divider";
import CircularProgress from '@mui/material/CircularProgress';
import Modal from "@mui/material/Modal";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import { GlobalStoreContext } from "../store";
import InfoBar from './InfoBar';
import VideoClipPlayer from './VideoClipPlayer';
import AIChat from './AIChat';
import OpenAI from "openai";

import 'bootstrap/dist/css/bootstrap.min.css';


function Info() {
  const { store } = useContext(GlobalStoreContext);
  const [loading, setLoading] = useState();
  const [chapterIndex, setChapterIndex] = useState(0);
  const [transcripts, setTranscripts] = useState();

  const openai = new OpenAI({
    apiKey: "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",
    organization: "org-QSgsUjazdi68MZZUFFfbLenV",
     dangerouslyAllowBrowser: true
  });
  /*const handleSendRequest = async () => {
    
    if (transcripts) {
      // If data is already fetched, don't fetch it again
      return;
    }
    setLoading(false);
    const transcript1 = store.transcript;
    openai.chat.completions.create({
        model: "gpt-3.5-turbo",
        messages: [{ role: "system", content: "You are a helpful assistant." },
        { role: "user", content: "return the transcript and encase all medical terms as <TERM></TERM> and don't say anything extra. " + transcript1}],
      })
        .then((res) => {
          setTranscripts(res.choices[0].message.content);
          setLoading(true);
          return res.choices[0].message.content;
         // console.log(res.choices[0].message.content);
        //  const temp = [...messages,{ content: input, fromUser: true }, { content: res.choices[0].message.content, fromUser: false }];
        //setMessages(temp);
       // setInput('');
          // Handle the response as needed
        })
        .catch((e) => {
          console.error(e);
          // Handle errors
        });
  };*/
  

  const timeConvert = (seconds) => {
    const roundedSeconds = Math.round(seconds);
    const min = Math.floor(roundedSeconds / 60);
    const sec = roundedSeconds % 60;
    // Formatting minutes and seconds to ensure leading zeros if necessary
    const formattedMin = min < 10 ? `${min}` : min;
    const formattedSec = sec < 10 ? `0${sec}` : sec;
  
    return `${formattedMin}:${formattedSec}`;
  }
  
  const handleTimestampClick = (index) => {
    setChapterIndex(index);
  }
  
  return (
    <div className="container-fluid p-0"  >
      <div class="row g-0" >
        <div class="col">
          <InfoBar />
        </div>
        <div class="row g-0" >
        <div class="col-md-7">
          <main>
            <div class="row g-0">
              <VideoClipPlayer url={store.videolink} start={store.chapterstart[chapterIndex]} end={store.chapterend[chapterIndex]} />
            </div>
          </main>
        </div>
        {/* style={{ position: "relative", top: 0, width: '100%', objectFit: 'contain' }} */}
        <div class="col-md-5" sx={{bgcolor: '#8c52ff'}}>
          <aside>
            <div class="row g-0">
        {store.chaptersummary.map((chaptersummary, index) => (
            <Accordion key={index} >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel-chapter-${index + 1}-content`}
                id={`panel-chapter-${index + 1}-header`}
                sx={{bgcolor: '#F1E6D4'}}
              >

                <Typography variant="h6"> Chapter {index + 1} </Typography>
              </AccordionSummary>
              <AccordionDetails >
               <Typography sx={{margin: '7px'}}>
                  Timestamp
                  <br/>
                  <a href="#" onClick={() => handleTimestampClick(index)}>
                     {timeConvert(store.chapterstart[index])} - {timeConvert(store.chapterend[index])}
                  </a>
                </Typography>
                <Divider />
                <Typography sx={{margin: '7px'}}>
                  <u>Summary</u>
                  <br/>
                  {chaptersummary || `No content available for Chapter ${index + 1}.`}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
        
        <div class="row g-0 ">
        <Accordion onChange={(event, isExpanded) => isExpanded}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel-transcript-content"
              id="panel-transcript-header"
              sx={{bgcolor:'#F1E6D4'}}
            >
              <Typography component="h1" variant="h6" alignText="center">Transcript</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ maxHeight: "500px", overflowY: "auto" }}>
              <Typography style={{ whiteSpace: 'pre-line' }}>
                {store.transcript||"no transcript"}
              </Typography>
            </AccordionDetails>
        </Accordion>
        </div>
        
        <div class="row g-0 ">
        <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel-transcript-content"
              id="panel-transcript-header"
              sx={{bgcolor:'#F1E6D4'}}
            >
              <Typography component="h1" alignItems='center' variant="h6">AI Chat</Typography>
            </AccordionSummary> 
             <AccordionDetails >
              <AIChat /> 
            </AccordionDetails> 
           </Accordion>
        </div>
        </aside>
      </div>
      </div>
    </div>
    </div>
  );
}

export default Info;
