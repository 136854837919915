import React, { useState, useContext, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { GlobalStoreContext } from "../store";
import InfoBar from './InfoBar';
import VideoClipPlayer from './VideoClipPlayer';
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflow: 'auto',
  maxHeight: '80%',
  '&:focus-visible': {
    outline: 'none'
  }
};

function Save() {
  const { store } = useContext(GlobalStoreContext);
  const [open, setOpen] = useState(false);
  const [chapterIndex, setChapterIndex] = useState(0);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  console.log(store.notes);
  const timeConvert = (seconds) => {
    const roundedSeconds = Math.round(seconds);
    const min = Math.floor(roundedSeconds / 60);
    const sec = roundedSeconds % 60;
    // Formatting minutes and seconds to ensure leading zeros if necessary
    const formattedMin = min < 10 ? `${min}` : min;
    const formattedSec = sec < 10 ? `0${sec}` : sec;
  
    return `${formattedMin}:${formattedSec}`;
  }
  
  const handleTimestampClick = (index) => {
    setChapterIndex(index);
  }
  
  return (
    <div className="container-fluid p-0" sx={{bgcolor: '#8c52ff'}} >
      <Grid container alignItems="center" sx={{bgcolor: '#8c52ff'}} >
        
        
        <Grid item xs={6} alignItems="center" >
          <VideoClipPlayer url={store.videolink} start={store.chapterstart[chapterIndex]} end={store.chapterend[chapterIndex]} />
        </Grid>
        {/* style={{ position: "relative", top: 0, width: '100%', objectFit: 'contain' }} */}
        <Grid item xs={6} style={{ display: 'block' }}>
          {store.chaptersummary.map((chaptersummary, index) => (
            <Accordion key={index} sx={{position: 'relative', top: 0}}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel-chapter-${index + 1}-content`}
                id={`panel-chapter-${index + 1}-header`}
                sx={{bgcolor: '#F1E6D4'}}
              >
                <Typography variant="h6"> Chapter {index + 1} </Typography>
              </AccordionSummary>
              <AccordionDetails >
               <Typography sx={{margin: '7px'}}>
                  Timestamp
                  <br/>
                  <a href="#" onClick={() => handleTimestampClick(index)}>
                     {timeConvert(store.chapterstart[index])} - {timeConvert(store.chapterend[index])}
                  </a>
                </Typography>
                <Divider />
                <Typography sx={{margin: '7px'}}>
                  <u>Summary</u>
                  <br/>
                  {chaptersummary || `No content available for Chapter ${index + 1}.`}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}

          
        </Grid>

        <Grid container alignItems="center" sx={{bgcolor: '#8c52ff'}} >
        <Grid item xs={6}>
        <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel-transcript-content"
              id="panel-transcript-header"
              sx={{bgcolor:'#F1E6D4'}}
            >
              <Typography component="h1" variant="h6">Transcript</Typography>
            </AccordionSummary>
            <AccordionDetails style={{bgcolor: '#8c52ff', maxHeight: "500px", overflowY: "auto" }}>
              <Typography>
                {store.transcript || "No transcript available."}
              </Typography>
            </AccordionDetails>
          </Accordion>

        </Grid>
        <Grid item xs={6}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-transcript-content"
                id="panel-transcript-header"
                sx={{bgcolor:'#F1E6D4'}}
              >
                <Typography>Notes</Typography>
              </AccordionSummary>
              <AccordionDetails style={{bgcolor: '#8c52ff', maxHeight: "500px", overflowY: "auto" }}>
              {store.notes && store.notes.length > 0 ? (
                store.notes.map((note, index) => (
                  <React.Fragment key={index}>
                    <Typography>{note}</Typography>
                    {index < store.notes.length - 1 && <Divider />}
                  </React.Fragment>
                ))
              ) : (
                <Typography>No notes available.</Typography>
              )}
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Grid>

      
    </div>
  );
}

export default Save;
