import React, { useState,useContext } from 'react';
import { Paper, TextField, List, ListItem, ListItemText, makeStyles, Button } from '@material-ui/core';
import { GlobalStoreContext } from "../store";
import axios from 'axios';
import OpenAI from "openai";
import transitions from '@material-ui/core/styles/transitions';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    height: 300,
    width: 'auto',
    margin: 'auto',
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
  },
  messageList: {
    height: 'calc(100% - 60px)',
    overflowY: 'auto',
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
  },
  messageInput: {
    width: 'calc(100% - 20px)',
    position: 'relative',
    top: 10,
    bottom: 10,
    left: 0,
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
  },
  button: {
    marginLeft: 10,
    height: '100%',
  },
}));

function AIChat() {

  const { store } = useContext(GlobalStoreContext);
  const classes = useStyles();
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);
  const openai = new OpenAI({
    apiKey: "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",
    organization: "org-QSgsUjazdi68MZZUFFfbLenV",
     dangerouslyAllowBrowser: true
  });
 /* openai.chat.completions.create({
    model: "gpt-3.5-turbo",
    messages: [{ role: "system", content: "You are a helpful assistant." }, { role: "user", content: input }],
  })
    .then((res) => {
      console.log(res.choices[0].message.content);
      // Handle the response as needed
    })
    .catch((e) => {
      console.error(e);
      // Handle errors
    });*/

  const handleChange = (e) => {
    setInput(e.target.value);
  };
  
  const handleSendRequest = async (userInput) => {
    const transcript1 = store.transcript;
    openai.chat.completions.create({
        model: "gpt-3.5-turbo",
        messages: [{ role: "system", content: "You are a transcript analyzer." },
        { role: "user", content: transcript1 },
        { role: "assistant", content: "Sure, how can I assist you further?" },
        { role: "user", content: input }],
      })
        .then((res) => {
          console.log(res.choices[0].message.content);
          const temp = [...messages,{ content: input, fromUser: true }, { content: res.choices[0].message.content, fromUser: false }];
        setMessages(temp);
        setInput('');
          // Handle the response as needed
        })
        .catch((e) => {
          console.error(e);
          // Handle errors
        });
  };
 

  return (
    <div className="App">
      <Paper className={classes.container} >
        <List className={classes.messageList}>
          {messages.map((message, i) => (
            <ListItem
              key={i}
              style={{
                justifyContent: message.fromUser ? 'flex-end' : 'flex-start',
                marginBottom: 10,
              }}
            >
              <ListItemText
                primary={message.content}
                style={{
                  background: message.fromUser ? '#007bff' : '#e5e5ea',
                  color: message.fromUser ? '#fff' : '#000',
                  borderRadius: 10,
                  padding: '8px 12px',
                  display: 'inline-block',
                  maxWidth: '70%',
                }}
              />
            </ListItem>
          ))}
          <div className={classes.messageInput}>
          <TextField
            variant="outlined"
            value={input}
            onChange={handleChange}
            placeholder="Send a Message"
            fullWidth
          />
          <Button className={classes.button} variant="contained" color="primary" onClick={handleSendRequest}>
            Send
          </Button>
        </div>
        </List>
        
      </Paper>
    </div>
  );
}

export default AIChat;