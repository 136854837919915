// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAw9G5Tu64BJTW2a4Ulrx3chGN7DohDySU",
  authDomain: "smoothlecture.firebaseapp.com",
  projectId: "smoothlecture",
  storageBucket: "smoothlecture.appspot.com",
  messagingSenderId: "814797196071",
  appId: "1:814797196071:web:a51341be1c07f5e2c27702",
  measurementId: "G-H433EBZS12"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);