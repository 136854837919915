import React, { useState, useContext, useEffect, useRef } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import {Dialog, DialogTitle, DialogContent, DialogActions, Grid } from '@mui/material';
import { getDatabase, ref, set, push } from "firebase/database";
import { getAuth } from "firebase/auth";

import { useNavigate } from "react-router-dom";

import { GlobalStoreContext } from "../store";

function writeUserData(userId,name, transcript,chapterSum,chapterS,ChapterE,URL,notes) {
  const db = getDatabase();
  push(ref(db, 'users/' + userId), {
    name: name,
    transcript: transcript,
    chapterSum: chapterSum,
    chapterStart: chapterS,
    chapterEnd: ChapterE,
    url: URL,
    notes: notes

  });
}

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
    maxHeight: '80%',
    '&:focus-visible': {
      outline: 'none'
    }
};

const InfoBar = () => {
  const [showForm, setShowForm] = useState(false);
  const [notes, setNotes] = useState('');
  const [allNotes, setAllNotes] = useState([]);
  
    const auth = getAuth();
    const user = auth.currentUser;

    const database = getDatabase();
    const { store } = useContext(GlobalStoreContext);
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [analysisName, setAnalysisName] = useState("");
    
    const [topicsOpen, setTopicsOpen] = useState(false);
    const handleTopicsOpen = () => setTopicsOpen(true);
    const handleTopicsClose = () => setTopicsOpen(false);

    const handleOpenModal = () => {
      setIsModalOpen(true);
    };
  
    const handleCloseModal = () => {
      setIsModalOpen(false);
    };
    
    const handleHome = () => {
      navigate('/Profile');
    };

    const handleSaveAnalysis = () => {
      console.log(analysisName);
      console.log(user.uid);
      console.log(store.transcript);
      const ts = store.transcript;
      const sum = store.chaptersummary;
      const start = store.chapterstart;
      const end = store.chapterend;
      const url = store.videolink;

      writeUserData(user.uid,analysisName,ts,sum,start,end,url,allNotes);
      // Implement your logic to save the analysis with the provided name
      // You can access the analysisName variable here
      // For simplicity, just close the modal in this example
      handleCloseModal();
      navigate('/Profile')
    };

    const navigateToProfile = (event) => {
      
        event.preventDefault();
         handleOpenModal();
        
    }
    const extractCategory = (topic) => {
      const categoryMatch = topic.match(/>(\w+)$/);
      return categoryMatch ? categoryMatch[1] : null;
    };
    const handleButtonClick = () => {
      setShowForm(true); // Set showForm to true to display the form
    };
  
    const handleFormClose = () => {
      setNotes('');
      setShowForm(false); // Set showForm to false to hide the form
    };
  
    const handleNotesChange = (event) => {
      setNotes(event.target.value); // Update the notes state as the user types
    };
  
    const handleFormSubmit = () => {
      // Add logic to handle form submission, e.g., sending notes to the server
      console.log('Notes submitted:', notes);
      setAllNotes([...allNotes, notes]);
      
      handleFormClose(); // Close the form after submission
    };

    return (
  
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static" sx={{ background: "black" }}>
            <Toolbar>
              <a onClick={handleHome} sx={{cursor: 'pointer'}}>
                <img
                    src="https://i.postimg.cc/Jh7XrQTQ/Telehealth-1.png"
                    alt="SLLogo"
                    style={{ width: '75px', height: '75px', objectFit: 'contain'  }}
                />
              </a>
              <Typography
                component="div"
                sx={{
                  fontSize: '25px',
                  flexGrow: 1,
                  textAlign: "center",
                  color: "",
                  fontWeight: "bold",
                  fontFamily: "system-ui",
                }}
              >
                Video Analysis
              </Typography>
              <Button variant="contained" sx={{mr: '12px'}} onClick={handleButtonClick}>
        Create Notes
      </Button>

              <Button variant="contained" color="primary" sx={{mr: '12px'}} onClick={handleTopicsOpen}>
                Show Topics
              </Button>

              <Button variant="contained" color="primary" onClick={navigateToProfile}>
                Save Analysis
              </Button>
    
              <Modal open={isModalOpen} onClose={handleCloseModal}>
                <Box
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 300,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    p: 2,
                    textAlign: 'center',
                  }}
                >
                  <Typography variant="h6">Enter Analysis Name:</Typography>
                  <TextField
                    label="Name"
                    value={analysisName}
                    onChange={(e) => setAnalysisName(e.target.value)}
                    sx={{ mt: 2 }}
                  />
                  <Button variant="contained" color="primary" onClick={handleSaveAnalysis} sx={{ mt: 2 }}>
                    Save
                  </Button>
                </Box>
              </Modal>
                
              <Modal
                open={topicsOpen}
                onClose={handleTopicsClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
                
                >
                <Box sx={modalStyle}>
                <Typography id="modal-title" variant="h6" component="div" sx={{ mb: 2 }}>
                    Topics
                </Typography>
                <List dense={true} id="modal-description">
                    {store.topics.map((topic, index) => (
                    <React.Fragment key={index}>
                        <ListItem>
                        <ListItemText primary={topic} />
                        </ListItem>
                        {index < store.topics.length - 1 && <Divider />}
                    </React.Fragment>
                    ))}
                </List>
                </Box>
            </Modal>

            <Dialog open={showForm} onClose={handleFormClose} maxWidth="md" fullWidth>
        <DialogTitle>Enter Notes</DialogTitle>
        <DialogContent>
        <TextField
                autoFocus
                margin="dense"
                id="notes"
                label="Notes"
                type="text"
                fullWidth
                multiline
                rows={8} // Adjust the number of rows for desired height
                variant="outlined" // Use outlined variant for a visually distinct text field
                value={notes}
                onChange={handleNotesChange}>

                </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFormClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleFormSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
              
            </Toolbar>
          </AppBar>
        </Box>
      );
}

export default InfoBar;