import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { GlobalStoreContext } from '../store'

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { getDatabase, ref, child, get } from "firebase/database";
import { getAuth } from "firebase/auth";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
const Profile = () => {
    const [names, setNames] = useState([]);
    const [chapterSum, setChapterSum] = useState([]);
    const [chapterStart, setChapterStart] = useState([]);
    const [chapterEnd, setChapterEnd] = useState([]);
    const [url, setUrl] = useState();
    const [transcript, setTranscript] = useState();
    const [notesK, setNotes] = useState([]);
    const { store } = useContext(GlobalStoreContext);
    const navigate = useNavigate();
    const auth = getAuth();
    const user = auth.currentUser.uid;
    const dbRef = ref(getDatabase());
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const snapshot = await get(child(dbRef, `users/${user}`));
          if (snapshot.exists()) {
            const data = snapshot.val();
            const namesArray = Object.values(data).map(entry => entry.name);
            const chapterSum = Object.values(data).map(entry => entry.chapterSum);
            const chapterStart = Object.values(data).map(entry => entry.chapterStart);
            const chapterEnd = Object.values(data).map(entry => entry.chapterEnd);
            const url = Object.values(data).map(entry => entry.url);
            const transcript = Object.values(data).map(entry => entry.transcript);
            const notes = Object.values(data).map(entry => entry.notes);
            setUrl(url);
            setNames(namesArray);
            setTranscript(transcript);
            setChapterSum(chapterSum);
            setChapterStart(chapterStart);
            setChapterEnd(chapterEnd);
            setNotes(notes);

          } else {
            console.log("No data available");
          }
        } catch (error) {
          console.error(error);
        }
      };
  
      fetchData();
    }, [user, dbRef]);
    

    //const defaultProfileImage = "https://th.bing.com/th/id/R.67827ff3dd64bbbcb160eefa6ab150a9?rik=j%2flB8VmEWIs9Bg&riu=http%3a%2f%2f3.bp.blogspot.com%2f-qDc5kIFIhb8%2fUoJEpGN9DmI%2fAAAAAAABl1s%2fBfP6FcBY1R8%2fs320%2fBlueHead.jpg&ehk=dMHPxs9WRYvMgQqfGxuhupwv%2fwiQMvsXHHD9ReK4kNs%3d&risl=&pid=ImgRaw&r=0";

    const handleBackArrow = (event) => {
        event.preventDefault();
        navigate('/upload');
    }

    const handleImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            //change image
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        // ? do you want to actually authenticate the form data (username, password)
       
    }
    const handleNameClick = (name, index) => {
        // Placeholder action, you can replace this with your logic
        console.log(`Index '${index}' clicked!`);
        console.log(chapterSum[index]);
        console.log(chapterStart[index]);
        console.log(chapterEnd[index]);
        console.log(url[index]);
        console.log(transcript[index]);
        store.setTranscript(transcript[index]);
        //store.setTopics(detectedTopics);
        store.setChapterSummary(chapterSum[index]);
        store.setChapterStart(chapterStart[index]);
        store.setChapterEnd(chapterEnd[index]);
        store.setVideolink(url[index]);
        store.setNotes(notesK[index]);
        navigate('/save');
        // Additional actions, such as navigating to a new page or updating state, can be added here
      };
    
    return (
            <Grid container component="main" sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center',bgcolor: '#8c52ff' }}>
              <CssBaseline />
              <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
                <Box
                  sx={{
                    my: 8,
                    mx: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center', // Align items to the left
                  }}
                >
                  <AddCircleOutlineIcon
                    sx={{
                      position: 'absolute',
                      top: '16px',
                      left: '16px',
                      cursor: 'pointer',
                      transition: 'all 0.3s',
                      ':hover': {
                        backgroundColor: 'primary.light',
                        transform: 'scale(1.10)',
                        transition: 'all 0.3s',
                      },
                      width: '27px',
                      height: '27px',
                    }}
                    onClick={handleBackArrow}
                  />
                  <Typography component="h1" variant="h5" sx={{ textAlign: 'center'  }}>
                    Saved Video Analyses
                  </Typography>

                  <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1, width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                    {names.map((name, index) => (
                      <Paper
                        key={index}
                        elevation={3} // Add elevation for a raised effect
                        sx={{
                          p: 2, // Add padding for spacing around the name
                          mt: 1, // Add margin between names
                          cursor: 'pointer',
                          '&:hover': { textDecoration: 'underline' },
                          textAlign: 'center',
                        }}
                        onClick={() => handleNameClick(name, index)}
                      >
                        {name}
                      </Paper>
                    ))}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          );
}

export default Profile;


