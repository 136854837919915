import React, { useState, useContext } from "react";
import axios from 'axios';
import Button from '@material-ui/core/Button';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useNavigate } from "react-router-dom";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getAuth, onAuthStateChanged} from "firebase/auth";
import {GlobalStoreContext} from "../store";
const { AssemblyAI } = require("assemblyai")
const apiKey = "f131a396c5fa40fd93b6ec01b9082b10"
const client = new AssemblyAI({ apiKey: apiKey });
const api = axios.create({
    baseURL: 'http://localhost:3000/Upload'
})
const Upload = () => {
    var transcript = "";
    var detectedTopics = [];
    var detectedChapter = [];
    var detectedStart = [];
    var detectedEnd = [];
    const { store } = useContext(GlobalStoreContext);
    const auth = getAuth();
    console.log(auth);
    const navigate = useNavigate();
    const [AIDone, setAIDone] = useState(0);
        
    const [uploadProgress, setUploadProgress] = useState(0);
    const [selectedFile, setSelectedFile] = useState(null);
    
    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleInfoPage = (event) => {
        event.preventDefault();
        navigate('/Info')
    };

    const AIUpload = (link) => {
        console.log("Analysis started");
        const config = {
            audio_url: link,
            iab_categories: true,
            summary_model: 'informative',
            summary_type: 'paragraph',
            auto_chapters: true,
            speaker_labels: true,
            speakers_expected: 2
          };
          const extractCategory = (topic) => {
            const categoryMatch = topic.match(/>(\w+)$/);
            const relevanceMatch = topic.match(/\d+\.\d+/);
          
            if (categoryMatch && relevanceMatch) {
              const category = categoryMatch[1];
              const relevancePercentage = parseFloat(relevanceMatch[0]);
              return `Audio is ${relevancePercentage.toFixed(0)}% relevant to ${category}`;
            }
          
            // Handle the case where the pattern matches are not found
            return topic;
          };
        const run = async() => {
            try {
                const res = await client.transcripts.create(config);
                for (const utterance of res.utterances) {
                    transcript = transcript + `\nSpeaker ${utterance.speaker}: ${utterance.text}\n`;
                    console.log(`Speaker ${utterance.speaker}: ${utterance.text}`)
                  }
                console.log("Analysis ended");
                //console.log(transcript.text);
                console.log("----------");
                
                
                // let topics = [];
                for (const [topic, relevance] of Object.entries(res.iab_categories_result.summary)) { 
                    //console.log(`Audio is ${relevance * 100} relevant to ${topic}`);
                    // topics.push(`Audio is ${relevance * 100} relevant to ${topic}`);
                    detectedTopics.push(`Audio is ${relevance * 100} relevant to ${topic}`);

                }
                // store.setTopics(topics);
                console.log("----------");
                for (const chapter of res.chapters) {
                   // console.log(`${chapter.start/1000}-${chapter.end/1000}: ${chapter.summary}`);
                    detectedChapter.push(chapter.summary);
                    detectedStart.push(chapter.start/1000);
                    detectedEnd.push(chapter.end/1000);
                }
                
            } catch (error) {
                console.error('Error uploading and transcribing audio:', error.message);
            }

        }
        run().then(() => {
            setAIDone(1);
            console.log("After run function");
            console.log("Transcript:", transcript); // Assuming transcript is a global variable
            store.setTranscript(transcript);
            const updatedDetectedTopics = [];
            for (const topic of detectedTopics) {
                updatedDetectedTopics.push(extractCategory(topic));
              }

            store.setTopics(updatedDetectedTopics);
            store.setChapterSummary(detectedChapter);
            store.setChapterStart(detectedStart);
            store.setChapterEnd(detectedEnd);
        });

    };
    const handleFileUpload = () => {
        
        const auth = getAuth();

        // Check for authentication state changes
        /*const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
            // User is signed in
            console.log('User is authenticated:', user.uid);
            // Perform actions for authenticated user
        } else {
            // User is signed out
            console.log('User is not authenticated');
            // Perform actions for non-authenticated user
        }
        });*/
        console.log("Uploading");
        // Ensure selectedFile is defined, for example, from a file input event
        if (!selectedFile) {
            console.error("No file selected for upload");
            return;
        }
        console.log(selectedFile);
        const storage = getStorage();

        // Create the file metadata
        /** @type {any} */
        const metadata = {
        contentType: 'video/mp4'
        };

        // Upload file and metadata to the object 'images/mountains.jpg'
        const storageRef = ref(storage, 'videos/' + selectedFile.name);
        const uploadTask = uploadBytesResumable(storageRef, selectedFile, metadata);

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on('state_changed',
        (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress(progress);
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
            case 'paused':
                console.log('Upload is paused');
                break;
            case 'running':
                console.log('Upload is running');
                break;
            }
        }, 
        (error) => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
            case 'storage/unauthorized':
                console.log("No permission");
                
                // User doesn't have permission to access the object
                break;
            case 'storage/canceled':
                // User canceled the upload
                break;

            // ...

            case 'storage/unknown':
                // Unknown error occurred, inspect error.serverResponse
                break;
            }
        }, 
        () => {
            // Upload completed successfully, now we can get the download URL
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log('File available at', downloadURL);
            store.setVideolink(downloadURL.toString());
            
            AIUpload(downloadURL);
            
            });
        }
        );
       /* const formData = new FormData();
        formData.append('file', selectedFile);
        const url = 'http://localhost:3000/Upload';
        console.log('URL:', url);
        api.post(url, formData, {
            onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                // Ensure setUploadProgress is defined and updates accordingly
                setUploadProgress(percentCompleted);
            }
        })
        .then(response => {
            console.log("uploaded");
           // AIUpload(selectedFile);
            // Handle success here, e.g., updating UI or notifying the user
        })
        .catch(error => {
            console.error(error);
            // Handle error here, e.g., showing an error message to the user
        });*/
    };

    return (
        <Grid container component="main" sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', bgcolor: '#8c52ff' }}>
        <CssBaseline />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square sx={{ display: 'flex', alignItems: 'center', position: 'relative', justifyContent: 'center', borderRadius: 7.0 }}>
        <Box
            sx={{
                my: 8,
                mx: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Typography component="h1" variant="h4">
                Upload Your Videos Here!
            </Typography>

            <Box noValidate sx={{ mt: 5, alignItems: 'center' }}>
                {/* Label for files/attachments */}
                <label htmlFor="videoFile" >
                    <input
                        type="file"
                        id="videoFile"
                        accept="video/*"
                        onChange={handleFileChange}
                        style={{ display: 'none',}} // Hide the input element
                    />
                    <Button
                        fullWidth
                        variant="contained"
                        component="span" // Make the button behave like a span
                        sx={{ mt: 3, mb: 2, bgcolor: '#8c52ff', color: 'white', '&:hover': { bgcolor: '#6933FF', } }} // Custom styling with hover effect
                    >
                        {selectedFile ? selectedFile.name : 'Choose File'} 
                    </Button>
                </label>

                <Button
                    onClick={handleFileUpload}
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 2, mb: 1, bgcolor:'#8c52ff', color: 'white', '&:hover': { bgcolor: '#1a237e' } }} // Custom styling with hover effect
                >
                    Upload
                </Button>
                

                <Box sx={{ mt: 2, textAlign: 'center', width: '100%' }}>
                    <LinearProgress variant="determinate" value={uploadProgress} />
                    { uploadProgress > 0  && uploadProgress < 100 && 
                        <p>{Math.round(uploadProgress)}% Uploading...</p> }
                    
                    { AIDone === 1 && 
                        <p>Analysis Completed!</p>
                    }
                </Box>
                <Box sx={{ mt: 2, textAlign: 'center', alignItems: 'center', width: '100%' }}>
                    
                    { uploadProgress === 100  && AIDone === 0 &&  
                        <p>Analysis Started...</p>  
                    }
                    
                </Box>
                {  AIDone === 1 &&
                    <Box sx={{ mt: 2, textAlign: 'center', width: '100%' }}>
                    <Button
                        onClick={handleInfoPage}
                        type="submit"
                        // fullWidth
                        variant="contained"
                        sx={{ mt: 2, alignItems: 'center', bgcolor:'#aaa9ad', color: 'white'}} // Custom styling with hover effect
                    >
                        View Information on Lecture 
                    </Button>
                    </Box>
                }
            </Box>
        </Box>
    </Grid>
</Grid>



        // PREVIOUS CODE
        // <form>
        //     <input
        //         type="file"
        //         onChange={handleFileChange}
        //         accept="video/*"
        //     />
        //     <Button
        //         variant="contained"
        //         color="primary"
        //         component="span"
        //         onClick={handleFileUpload}
        //     >
        //         Upload
        //     </Button>
        //     <LinearProgress variant="determinate" value={uploadProgress} />
        // </form>
    );
};

export default Upload;