import React, { useState, useContext, useEffect, useRef } from "react";

// Define VideoClipPlayer outside of Info to avoid re-creating the function on each render
const VideoClipPlayer = ({ url, start, end }) => {
    const videoRef = useRef(null);
  
    useEffect(() => {
      const video = videoRef.current;
      if (!video) return;
        
      const handleLoadedMetadata = () => {
        video.currentTime = start;
      };
  
      const handleTimeUpdate = () => {
        if (video.currentTime >= end) {
          video.pause();
          // Optional: Loop back to start
          // video.currentTime = start;
          // video.play();
        }
      };
  
      video.addEventListener('loadedmetadata', handleLoadedMetadata);
      video.addEventListener('timeupdate', handleTimeUpdate);
  
      return () => {
        video.removeEventListener('loadedmetadata', handleLoadedMetadata);
        video.removeEventListener('timeupdate', handleTimeUpdate);
      };
    }, [start, end]);
  
    // Whenever start changes, we want to update the current time of the video
    useEffect(() => {
        const video = videoRef.current;
        if (!video) return;
        if (video && isFinite(start)) {
        video.currentTime = start;
        }
    }, [start]);

    return (
      <video ref={videoRef} width="720" controls preload="metadata" >
        <source src={url} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    );
  };

  export default VideoClipPlayer;