//store reducer (redux)
import { createContext, useReducer } from 'react';
import { Global } from '@emotion/react';
import { StarRateTwoTone, Store } from '@mui/icons-material';

export const GlobalStoreContext = createContext({});

export const initialState = {
    transcript: "",
    topics: [],
    chaptersummary: [],
    chapterstart: [],
    chapterend: [],
    videolink: "",
    notes: []
}

export const GlobalStoreActionType = {
    SET_TRANSCRIPT: "SET_TRANSCRIPT",
    SET_TOPICS: "SET_TOPICS",
    SET_CHAPTERSUMMARY: "SET_CHAPTERSUMMARY",
    SET_CHAPTERSTART: "SET_CHAPTERSTART",
    SET_CHAPTEREND: "SET_CHAPTEREND",
    SET_VIDEOLINK: "SET_VIDEOLINK",
    SET_NOTES: "SET_NOTES"
}

function GlobalStoreContextProvider(props) {
    
    const globalStoreReducer = (state, action) => {
        console.log(state);
        console.log(action);
      switch (action.type) {
        case GlobalStoreActionType.SET_TRANSCRIPT:{
            return{
                ...state,
                transcript: action.payload
            }
        }
        case GlobalStoreActionType.SET_TOPICS: {
            return {
                ...state,
                topics: action.payload.topics
            }
        }
        case GlobalStoreActionType.SET_CHAPTERSUMMARY: {
            return {
                ...state,
                chaptersummary: action.payload.chaptersummary
            }
        }
        case GlobalStoreActionType.SET_CHAPTEREND: {
            return {
                ...state,
                chapterend: action.payload.chapterend
            }
        }
        case GlobalStoreActionType.SET_CHAPTERSTART: {
            return {
                ...state,
                chapterstart: action.payload.chapterstart
            }
        }
        case GlobalStoreActionType.SET_VIDEOLINK: {
            return {
                ...state,
                videolink: action.payload.videolink
            }
        }
        case GlobalStoreActionType.SET_NOTES: {
            return {
                ...state,
                notes: action.payload.notes
            }
        }
        default:
            return state;
      }
    }

    const [store, dispatch] = useReducer(globalStoreReducer, initialState);

    // Functions to change the global store
    store.setTranscript = (transcript) => {
        dispatch({
            type: GlobalStoreActionType.SET_TRANSCRIPT,
            payload: transcript 
        });
    }
    store.setTopics = (topics) => {
        dispatch({
            type: GlobalStoreActionType.SET_TOPICS,
            payload: { topics }
        });
    }
    store.setChapterSummary = (chaptersummary) => {
        dispatch({
            type: GlobalStoreActionType.SET_CHAPTERSUMMARY,
            payload: { chaptersummary }
        });
    }
    store.setChapterStart = (chapterstart) => {
        dispatch({
            type: GlobalStoreActionType.SET_CHAPTERSTART,
            payload: { chapterstart }
        });
    }
    store.setChapterEnd = (chapterend) => {
        dispatch({
            type: GlobalStoreActionType.SET_CHAPTEREND,
            payload: { chapterend }
        });
    }
    store.setVideolink = (videolink) => {
        dispatch({
            type: GlobalStoreActionType.SET_VIDEOLINK,
            payload: { videolink }
        });
    }
    store.setNotes = (notes) => {
        dispatch({
            type: GlobalStoreActionType.SET_NOTES,
            payload: { notes }
        });
    }

    return (
        <GlobalStoreContext.Provider value={ { store } }>
              {props.children}
        </GlobalStoreContext.Provider>
    );
}

export default GlobalStoreContext;
export { GlobalStoreContextProvider };